<template>
  <div
    :class="isDragOver && 'is-drag-over'"
    @dragenter.prevent="dragStartHandler"
    @dragover.prevent="dragOverHandler"
    @dragleave.prevent="dragLeaveHandler"
    @drop.prevent="dropHandler"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'DropZoneFiles',
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    isDragOver: false,
    isDragOverTimeout: null
  }),
  methods: {
    dropHandler (e) {
      if (this.isDisabled) return

      this.finishDrag()
      this.$emit('drop-files', [...e.dataTransfer.files])
    },

    dragStartHandler () {
      if (this.isDisabled) return

      this.startDrag()
    },
    dragOverHandler () {
      if (this.isDisabled) return

      this.startDrag()
    },
    dragLeaveHandler () {
      if (this.isDisabled) return

      this.finishDrag()
    },

    startDrag () {
      clearTimeout(this.isDragOverTimeout)
      this.isDragOver = true
    },
    finishDrag () {
      this.isDragOverTimeout = setTimeout(() => {
        this.isDragOver = false
      }, 0)
    }
  }
}
</script>
