<template>
  <InfoText
    label="В наличии"
    class="info--center"
  >
    <span class="flex gap-xxs color--orange">
      <svgicon
        icon="nav/sidebar/warehouse-reserve"
        width="25"
        height="25"
      />

      {{ count }}
    </span>
  </InfoText>
</template>

<script>
import InfoText from '@/components/simple/text/InfoText'
export default {
  name: 'InfoInStock',
  components: { InfoText },
  props: {
    count: {
      type: Number,
      required: true
    }
  }
}
</script>
