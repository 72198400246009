export const mixinInStock = {
  data: () => ({
    inStock: 0
  }),
  computed: {
    getInStockBy () {
      return (article) => this.$store.getters['warehouse/getMaterialInStockBy'](article)
    }
  },
  methods: {
    setInStockBy (article) {
      this.inStock = this.getInStockBy(article)
    },
    resetInStock () {
      this.inStock = 0
    }
  }
}
