<template>
  <div class="input-file-drag column gap-xs">
    <ImagePreview
      v-if="fileToShow"
      class="input-file-drag__preview border--main"
      :file="fileToShow"
    >
      <VButton
        v-if="isEdit"
        icon="remove"
        class="input-file-drag__button-remove"
        size="sm"
        @click.native="removeActiveImage"
      />
    </ImagePreview>
    
    <DropZoneFiles
      v-else
      class="input-file-drag__drop-area column ai-c border--main"
      :class="!isEdit && 'bg--disabled'"
      :is-disabled="!isEdit"
      @drop-files="dropFilesHandler"
    >
      <input
        ref="inputFiles"
        type="file"
        accept="image/*"
        :name="name"
        hidden
        :multiple="multiple"
        @change="uploadFilesHandler"
      />

      <div v-if="isEdit" class="input-file-drag__drop-area-content column gap-xs ai-c">
        <label class="input-file-drag__label">{{label}}</label>

        <span class="input-file-drag__description color--gray">
          Загрузите файлы простым переносом или по кнопке ниже
        </span>

        <VButton
          text="Выберите файл"
          class="input-file-drag__button-upload"
          size="l"
          @click.native="$refs.inputFiles.click()"
        />
      </div>
    </DropZoneFiles>

    <GridImagePreview
      v-if="hasPreviewGrid"
      v-model="modelValue"
      :count-files="countFiles"
      :is-edit="isEdit"
      @change-active="setActiveIndex"
    />
  </div>
</template>

<script>
import DropZoneFiles from '@/components/files/DropZoneFiles/DropZoneFiles'
import ImagePreview from '@/components/files/ImagePreview/ImagePreview'
import GridImagePreview from '@/components/files/GridImagePreview/GridImagePreview'
import VButton from '@/components/simple/button/VButton'
import { mixinInputFiles } from '@/mixins/form/mixinInputFiles'

export default {
  name: 'InputFileDrag',
  components: { GridImagePreview, ImagePreview, DropZoneFiles, VButton },
  mixins: [mixinInputFiles],
  props: {
    label: {
      type: String,
      required: false,
      default: 'Загрузите Фото'
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    hasPreviewGrid: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    activeIndex: null
  }),
  computed: {
    fileToShow () {
      return this.modelValue[this.activeIndex]
    }
  },
  watch: {
    countFiles (newValue, oldValue) {
      if (oldValue === 0) {
        this.setActiveIndex(0)
      }
    },
    isEdit: {
      handler (bool) {
        if (bool) return

        this.setActiveIndex(0)
      },
      immediate: true
    }
  },
  methods: {
    dropFilesHandler (files) {
      this.addOnlyImages(files)
    },

    uploadFilesHandler () {
      this.addOnlyImages(this.$refs.inputFiles.files)
    },

    addOnlyImages (files) {
      let images = []

      const length = files.length
      for (let i = 0; i < length; i++) {
        const file = files[i]

        if (file.type.startsWith('image')) {
          images.push(file)
        }
      }

      if (!this.multiple) {
        images = [images[0]]
      }

      this.addFiles(images)
    },

    removeActiveImage () {
      this.removeFileHandler({ id: this.fileToShow?.id, index: this.activeIndex })

      const index = this.activeIndex > 0 ?
        (this.activeIndex - 1) : this.countFiles ?
          0 : null

      this.setActiveIndex(index)
    },

    setActiveIndex (index) {
      this.activeIndex = index
    }
  }
}
</script>
