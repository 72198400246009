<template>
  <div class="grid-image-preview">
    <button
      v-if="isEdit"
      class="grid-image-preview__button-plus grid-image-preview__button btn-reset pseudo-plus"
      type="button"
      @click="setActive(null)"
    />

    <DropZone
      v-for="(item, idx) of filesFiltered"
      :key="idx"
      :index="idx"
      @on-drop="sortingFiles"
    >
      <DragItem :index="idx">
        <ImagePreview
          class="grid-image-preview__image border--main"
          :file="item"
          @click.native="setActive(idx)"
        />
      </DragItem>
    </DropZone>

    <button
      v-show="visibleButtonMore"
      type="button"
      class="grid-image-preview__button-more grid-image-preview__button btn-reset"
      @click="toggleShowMore"
    >
      {{ buttonMoreText }}
    </button>
  </div>
</template>

<script>
import ImagePreview from '@/components/files/ImagePreview/ImagePreview'
import DropZone from '@/components/DragNDrop/DropZone'
import DragItem from '@/components/DragNDrop/DragItem'

export default {
  name: 'GridImagePreview',
  components: {
    DragItem,
    DropZone,
    ImagePreview
  },
  model: {
    prop: 'files',
    event: 'changeSort'
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    countFiles: {
      type: Number,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    showMore: false
  }),
  computed: {
    filesFiltered () {
      if (this.showMore) {
        return this.files
      }

      return this.files.slice(0, 14)
    },

    visibleButtonMore () {
      return this.countFiles > 14
    },
    buttonMoreText () {
      if (this.showMore) {
        return 'Скрыть фото'
      }

      return `Еще ${this.moreFiles}\u00A0фото`
    },
    moreFiles () {
      return this.countFiles - 14
    }
  },
  methods: {
    setActive (index) {
      this.$emit('change-active', index)
    },

    toggleShowMore () {
      this.showMore = !this.showMore
    },

    sortingFiles ({ from, to }) {
      if (!this.isEdit) return

      const dragFile = this.files.splice(from, 1)[0]
      this.files.splice(to, 0, dragFile)
      this.setActive(to)
    }
  }
}
</script>
