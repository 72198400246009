<template>
  <BaseModal class="modal-kp" title="Формирование КП" @close="closeModal">
    <VForm class="d-flex flex-col ai-c" v-model="formData" @request="copyKP">
      <div class="modal-kp__fields pb-40 mb-40">
        <div class="form-grid--col-3 mb-60">
          <VSelect
            label="Основание"
            name="base"
            placeholder="Не выбрано"
            :options="baseList"
            required
          >
            <Validation for="required">Поле обязательно</Validation>
          </VSelect>

          <VSelect
            label="Покрытие"
            name="coating"
            placeholder="Не выбрано"
            :options="coatingOptionsFiltered"
            :value="data.coating"
            searchable
          >
            <Validation for="required">Поле обязательно</Validation>
          </VSelect>

          <VSelect
            label="Цвет покрытия"
            name="color"
            :options="colorOptionsFiltered"
            :value="data.color"
            searchable
          >
            <Validation for="required">Поле обязательно</Validation>
          </VSelect>
        </div>

        <div class="form-grid--col-5 mb-16">
          <p>Категории цен</p>

          <VCheckbox
            v-model="isNotCoating"
            label="Без покрытия"
            name="is_not_coating"
          />

          <VCheckbox
            v-model="isCoating"
            label="С покрытием"
            name="is_coating"
            :isDisabled="coatingDisabled"
          />

          <p>Кол-во</p>

          <AppInfoTextBlock
            text="Внесите стоимость покрытия на 1 пружину из калькулятора"
            :length="10"
            otherShowText="Расчет покрытия"
            notLabel
          />
        </div>

        <div
          v-for="(item, i) of pricesList"
          :key="i"
          class="form-grid--col-5 mb-16"
        >
          <VInput :name="item.name.name" :value="item.name.value" disabled />
          <VInput :name="item.notCoating.name" :value="item.notCoating.value" />
          <VInput :name="item.coating.name" :value="item.coating.value" />
          <VInput :name="item.count.name" :pattern="pattern.num">
            <Validation for="pattern" key-text="num" />
          </VInput>
          <VInput
            :name="item.calculation.name"
            :pattern="pattern.num100"
            @input="calcCoat"
          >
            <Validation for="pattern">Число до сотых</Validation>
          </VInput>
        </div>
      </div>

      <div
        v-if="formData.base"
        class="modal-kp__info mb-40"
        id="modal-kp__info"
      >
        <p>{{ formData.base.name }} {{ data.article }}</p>
        <b>{{ data.name }}</b>
        <p v-if="data.material">Материал: {{ data.material.name }}</p>
        <p v-if="data.weight">Масса пружины (кг): {{ data.weight }}</p>
        <div v-if="technical">
          <br />
          <b>Технические характеристики:</b>
          <p v-if="data.d_wire">Диаметр проволоки (мм): {{ data.d_wire }}</p>
          <p v-if="data.diameter">{{ diameterLabel }}: {{ data.diameter }}</p>
          <p v-if="data.length">{{ lengthLabel }}: {{ data.length }}</p>
          <p v-if="data.width">Ширина (мм): {{ data.width }}</p>
          <p v-if="data.n_n1">{{ turnsLabel }}: {{ data.n_n1 }}</p>
          <p v-if="data.product_winding_dir">Направление навивки: {{ data.product_winding_dir.name }}</p>
        </div>
        <div v-if="isNotCoating && sumlist.length">
          <br />
          <b v-if="isNotCoatBlock">Стоимость с НДС без покрытия:</b>
          <p v-for="(item, i) of infoNotCoat" :key="i">
            <span
              >{{ item.count }} шт. ► {{ item.not_coat_text }}/шт. Сумма:
              {{ item.sum_not_coat }}</span
            >
            <i v-if="item.not_coat && i !== 0 && item.benefit_not_coat">
              &nbsp; Выгоднее на {{ item.benefit_not_coat }} % за шт.</i
            >
          </p>
        </div>
        <div v-if="!coatingDisabled && formData.is_coating && sumlist.length">
          <br />
          <b>Стоимость с НДС с покрытием:</b>
          <p v-if="formData.coating">Покрытие: {{ formData.coating.name }}</p>
          <p v-if="formData.color">Цвет: {{ formData.color.name }}</p>
          <p v-for="(item, i) of infoCoat" :key="i">
            <span
              >{{ item.count }} шт. ► {{ item.coat_text }}/шт. Сумма:
              {{ item.sum_coat }}</span
            >
            <i v-if="item.coat && i !== 0 && item.benefit_coat">
              &nbsp; Выгоднее на {{ item.benefit_coat }} % за шт.</i
            >
          </p>
        </div>
        <br />
        <hr />
      </div>

      <VButton
        text="Копировать КП"
        type="submit"
        class="m-center"
        color="primary"
        size="xl"
      />
    </VForm>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/ui/Modal/BaseModal'
import VForm from '@/components/Form/VForm'
import VSelect from '@/components/Form/VSelect'
import VInput from '@/components/Form/Vinput/VInput.vue'
import Validation from '@/components/Form/Validation'
import VCheckbox from '@/components/Form/VCheckbox'
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock'
import VButton from '@/components/simple/button/VButton'

import { PATTERN_LIST } from '@/const/validation'
import { formatRUB } from '@/utils/format/number'
import { mixinCoatingAndColorOptionsFiltered } from '@/mixins/views/mixinCoatingAndColorOptionsFiltered'
import { fixedNumberCopy } from '@/utils/number'

export default {
  name: 'ModalCopy',
  components: {
    BaseModal,
    VForm,
    VSelect,
    VInput,
    Validation,
    VCheckbox,
    AppInfoTextBlock,
    VButton
  },
  mixins: [mixinCoatingAndColorOptionsFiltered],
  props: {
    title: {
      type: String,
      default: 'Сформировать КП'
    },
    data: {
      type: Object
    }
  },
  data: () => ({
    formData: {},
    baseList: [
      {
        id: 1,
        name: 'По параметрам'
      },
      {
        id: 2,
        name: 'По чертежу'
      },
      {
        id: 3,
        name: 'По эскизу'
      },
      {
        id: 4,
        name: 'По образцу'
      },
      {
        id: 5,
        name: 'ГОСТ'
      },
      {
        id: 6,
        name: 'ОСТ'
      },
      {
        id: 7,
        name: 'DIN'
      }
    ],
    isNotCoating: true,
    isCoating: false,
    pricesList: [],
    pattern: PATTERN_LIST,
    sumlist: [],
    isNotCoatBlock: false,
    coatingDisabled: true,
    copyText: ''
  }),
  computed: {
    infoCoat: function () {
      const filterList = this.sumlist.filter(item => !!item.coat)
      const last = filterList[0]
      filterList.forEach(item => {
        if (last && item.coat < last.coat) {
          item.benefit_coat = Math.round((item.coat * 100) / last.coat - 100)
        }
      })
      return filterList
    },
    infoNotCoat: function () {
      const filterList = this.sumlist.filter(item => !!item.not_coat)
      const last = filterList[0]
      filterList.forEach(item => {
        if (last && item.not_coat < last.not_coat) {
          item.benefit_not_coat = Math.round(
            (item.not_coat * 100) / last.not_coat - 100
          )
        }
      })
      return filterList
    },
    currentCoating () {
      return this.formData.coating
    },
    currentColor () {
      return this.formData.color
    },
    technical () {
      if (
        this.data.d_wire ||
        this.data.diameter ||
        this.data.length ||
        this.data.width ||
        this.data.n_n1 ||
        this.data.product_winding_dir
      ) { return true } else return false
    },
    turnsLabel () {
      switch (this.data.spring_type.id) {
        case 1:
        case 6:
          return 'n/n1'
        case 2:
        case 7:
          return 'n1'
        default:
          return 'n'
      }
    },
    diameterLabel () {
      switch (this.data.spring_type.id) {
        case 5:
        case 9:
          return 'Диаметр внутр. (мм)'
        default:
          return 'Диаметр наружн. (мм)'
      }
    },
    lengthLabel () {
      switch (this.data.spring_type.id) {
        case 2:
          return 'Длина по зацепам (мм)'
        default:
          return 'Длина (мм)'
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    setsumlist () {
      this.sumlist = []
      this.isNotCoatBlock = false
      this.pricesList.forEach((_, i) => {
        const countName = 'count ' + (i + 1)
        const count = Number(this.formData[countName])
        const notCoatName = 'cost_not_coating ' + (i + 1)
        let notCoat = this.formData[notCoatName]
        const coatName = 'cost_coating ' + (i + 1)
        let coat = this.formData[coatName]
        let sumNotCoat = null
        let sumCoat = null

        if (notCoat && typeof notCoat === 'string') notCoat = Number(notCoat.replace(',', '.'))
        if (coat && typeof coat === 'string') coat = Number(coat.replace(',', '.'))

        if (count) {
          if (notCoat) sumNotCoat = count * notCoat
          if (coat) sumCoat = count * coat
          if (sumNotCoat) this.isNotCoatBlock = true

          this.sumlist.push({
            count: count,
            not_coat: notCoat,
            coat: coat,
            not_coat_text: notCoat ? formatRUB(notCoat) : null,
            coat_text: coat ? formatRUB(coat) : null,
            sum_coat: sumCoat ? formatRUB(sumCoat) : null,
            sum_not_coat: sumNotCoat ? formatRUB(sumNotCoat) : null,
            benefit_coat: null,
            benefit_not_coat: null
          })
        }
      })
    },
    calcCoat () {
      this.data.prices.forEach((_, i) => {
        const notCoatName = 'cost_not_coating ' + (i + 1)
        const notCoat = Number(this.formData[notCoatName].replace(',', '.'))
        const calcName = 'calculation ' + (i + 1)
        const calc = Number(this.formData[calcName].replace(',', '.'))
        const coatName = 'cost_coating ' + (i + 1)

        if (!calc || calc === 0) {
          this.formData[coatName] = this.data.prices[i].cost_coating
        } else {
          if (notCoat) this.formData[coatName] = fixedNumberCopy(notCoat + calc, 2)
          else this.formData[coatName] = null
        }
      })
    },
    setCoatingDisabled () {
      if (this.formData.coating && this.formData.color) { this.coatingDisabled = false } else {
        this.formData.is_coating = false
        this.coatingDisabled = true
      }
    },
    copyKP () {
      const info = document.getElementById('modal-kp__info')
      this.$copyText(info)
    }
  },
  mounted () {
    this.data.prices.forEach((item, i) => {
      const price = {
        name: {
          name: 'name ' + (i + 1),
          value: item.name
        },
        notCoating: {
          name: 'cost_not_coating ' + (i + 1),
          value: item.cost_not_coating
        },
        coating: {
          name: 'cost_coating ' + (i + 1),
          value: item.cost_coating
        },
        count: {
          name: 'count ' + (i + 1)
        },
        calculation: {
          name: 'calculation ' + (i + 1)
        }
      }
      this.pricesList.push(price)
    })
  },
  watch: {
    formData: {
      handler () {
        this.setsumlist()
        this.setCoatingDisabled()
      },
      deep: true
    }
  }
}
</script>
