<template>
  <div class="form-grid--col-3">
    <VInput
      label="Диаметр проволоки (мм)"
      name="d_wire"
      :disabled="!canEdit"
      :pattern="pattern.num100"
    >
      <Validation for="pattern" key-text="num100" />
    </VInput>

    <VInput
      :label="lengthLabel"
      name="length"
      :disabled="!canEdit"
      :pattern="pattern.num100"
    >
      <Validation for="pattern" key-text="num100" />
    </VInput>

    <VInput
      label="Вес (кг)"
      name="weight"
      :disabled="!canEdit"
      :pattern="pattern.num10000"
    >
      <Validation for="pattern" key-text="num10000" />
    </VInput>

    <VInput
      :label="diameterLabel"
      name="diameter"
      :disabled="!canEdit"
    />

    <VInput
      label="Ширина (мм)"
      name="width"
      :disabled="!canEdit"
      :pattern="pattern.num100"
    >
      <Validation for="pattern" key-text="num100" />
    </VInput>

    <VSelect
      label="Сталь"
      name="material"
      :options="$store.state.records.properties.material"
      :disabled="!canEdit"
    />

    <VInput
      v-if="visibleTurns"
      :label="turnsLabel"
      name="n_n1"
      :disabled="!canEdit"
    />
    
    <VSelect
      v-if="!(springTypeId === 4)"
      label="Направление навивки"
      name="product_winding_dir"
      :options="$store.state.records.properties.winding_direction"
      :disabled="!canEdit"
    />   
  </div>
</template>

<script>
import VInput from '@/components/Form/Vinput/VInput.vue'

import { mixinCatalogProductIsEdit } from '@/mixins/views/Catalog/Product/mixinCatalogProductIsEdit'
import Validation from '@/components/Form/Validation.vue'
import { PATTERN_LIST } from '@/const/validation'
import { mixinCatalogProductMainFields } from '@/mixins/views/Catalog/Product/mixinCatalogProductMainFields'
import VSelect from '@/components/Form/VSelect.vue'

export default {
  name: 'ProductMainFieldsGrid',
  components: { VSelect, Validation, VInput },
  inject: ['formData'],
  mixins: [mixinCatalogProductIsEdit, mixinCatalogProductMainFields],
  data: () => ({
    pattern: PATTERN_LIST
  }),
  computed: {
    springTypeId () {
      return this.formData?.spring_type?.id
    }
  }
}
</script>
