<template>
  <div class="product-main-fields d-flex gap-s">
    <InputFileDrag
      class="product-main-fields__photo-box"
      name="files_images"
      multiple
      has-preview-grid
      :is-edit="canEdit"
      @onRemoveFile="ADD_FILE_TO_DELETE"
    />

    <div class="product-main-fields__box box">
      <InfoInStock
        class="product-main-fields__in-stock"
        :count="inStock"
      />

      <div class="product-main-fields__grid column gap-s">
        <VInput
          label="Наименование"
          name="name"
          required
          :disabled="!canEdit"
        >
          <Validation for="required"/>
        </VInput>

        <VInput
          label="Примечание к товару"
          name="note_product"
          type="textarea"
          :disabled="!canEdit"
        />

        <ProductMainFieldsGrid />

        <VSelect
          label="Марка техники"
          name="tech_brand"
          :options="$store.state.handbook.techBrand.list"
          searchable
          multiple
          :disabled="!canEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoInStock from '@/components/views/Catalog/Info/InfoInStock'
import InputFileDrag from '@/components/Form/VInputFiles/InputFileDrag'
import Validation from '@/components/Form/Validation'
import VInput from '@/components/Form/Vinput/VInput'
import VSelect from '@/components/Form/VSelect'

import { mixinCatalogProductIsEdit } from '@/mixins/views/Catalog/Product/mixinCatalogProductIsEdit'
import { mixinInStock } from '@/mixins/mixinInStock'
import ProductMainFieldsGrid from '@/components/views/Catalog/Product/ProductMainFieldsGrid.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'ProductMainFields',
  components: {
    ProductMainFieldsGrid,
    InfoInStock,
    InputFileDrag,
    Validation,
    VInput,
    VSelect
  },
  mixins: [mixinCatalogProductIsEdit, mixinInStock],
  inject: ['formData'],
  data: () => ({
    timeoutId: null
  }),
  watch: {
    'formData.article': {
      handler (article) {
        clearTimeout(this.timeoutId)

        if (article) {
          return this.setTimeoutId()
        }

        this.resetInStock()
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('catalog/product', {
      ADD_FILE_TO_DELETE: 'ADD_FILE_TO_DELETE'
    }),
    setTimeoutId () {
      this.timeoutId = setTimeout(this.refreshInStock, 500)
    },
    refreshInStock () {
      this.setInStockBy(this.formData.article)
    }
  }
}
</script>
