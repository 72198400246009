<template>
  <div class="form-grid--col-3 box">
    <VSelect
      label="Тип пружин"
      name="spring_type"
      :options="$store.state.records.properties.product_type"
      :disabled="!canEdit"
      :allow-empty="false"
      required
    >
      <Validation for="required"/>
    </VSelect>

    <InputArticle
      :is-disabled="!canEdit"
    />

    <VSelect
      label="Разделы"
      name="categories"
      :options="$store.state.catalog.categories.categoriesHandbook"
      multiple
      searchable
      :disabled="!canEdit"
      required
    >
      <Validation for="required"/>
    </VSelect>

    <VSelect
        label="Вид пружины"
        name="shape"
        :options="isType1 ? shapeAll : shapeType2"
        :disabled="!canEdit || !(isType1 || isType2)"
    />

    <VSelect
      label="Взаимозаменяемые артикулы"
      name="additional_articles"
      :options="$store.state.records.articlesAll.products"
      item-text="article"
      multiple
      searchable
      :disabled="!canEdit"
    />
  </div>
</template>

<script>
import InputArticle from '@/components/Form/Vinput/InputArticle'
import Validation from '@/components/Form/Validation'
import VSelect from '@/components/Form/VSelect'
import { mapGetters } from 'vuex'

import { mixinPositionType } from '@/mixins/views/Production/Position/mixinPositionType'
import { mixinCatalogProductIsEdit } from '@/mixins/views/Catalog/Product/mixinCatalogProductIsEdit'

export default {
  name: 'ProductHeadFields',
  components: { InputArticle, Validation, VSelect },
  mixins: [mixinCatalogProductIsEdit, mixinPositionType],
  computed: {
    ...mapGetters('records', {
        shapeAll: 'shapeAll',
        shapeType2: 'shapeType2'
    })
  }
}
</script>
