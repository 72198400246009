<template>
  <VInput
    v-model="modelValue"
    :label="label"
    class="input-clear"
    ref="input"
    :input-type="inputType"
    :disabled="isDisabled"
    :required="required"
    :pattern="pattern"
  >
    <Validation for="required" />
    <slot />

    <template v-if="!isDisabled && hasButtonRemove" v-slot:after>
      <VButton
        class="input-clear__button"
        size="sm"
        icon="remove"
        @click.native="onRemove"
      />
    </template>
  </VInput>
</template>

<script>
import Validation from '@/components/Form/Validation'
import VButton from '@/components/simple/button/VButton'
import VInput from '@/components/Form/Vinput/VInput'

import { mixinInputModel } from '@/mixins/form/mixinInputModel'

export default {
  name: 'InputClear',
  components: {
    Validation,
    VButton,
    VInput
  },
  mixins: [mixinInputModel],
  props: {
    value: {
      type: [String, Number, null],
      default: null
    },
    label: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    inputType: {
      type: String
    },
    pattern: {
      type: RegExp
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hasButtonRemove: {
      type: Boolean,
      required: false,
      default: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    isEmitRemove: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onRemove () {
      if (this.isEmitRemove) {
        return this.$emit('on-remove')
      }

      this.$refs.input.clear()
    }
  }
}
</script>
