<template>
  <div class="box">
    <h4 class="mb-30">Дополнительные характеристики</h4>
    
    <PositionFormDetails
      v-model="modelValue"
      ref="PositionFormDetails"
      :type-position="typePosition"
      :shape-catalog="shapeCatalog"
      is-catalog
      :is-disabled="isDisabledPositionFields"
      :is-required="false"
    />
  </div>
</template>

<script>
import PositionFormDetails from '@/components/production/position/PositionFormDetails'
import { mixinInputModel } from '@/mixins/form/mixinInputModel'
export default {
  name: 'ProductPositionFields',
  components: { PositionFormDetails },
  mixins: [mixinInputModel],
  props: {
    typePosition: {
      type: Object,
      required: true
    },
    shapeCatalog: {
      type: Object,
      required: false
    }
  },
  computed: {
    isDisabledPositionFields () {
      return !this.$store.state.catalog.product.isEdit
        || !this.$store.getters['permission/permissionUpdateProductPositionFields']
    }
  }
}
</script>
