<template>
  <div class="column gap-m box">
    <h3>Общие характеристики</h3>

    <div class="form-grid--col-2">
      <VSelect
        label="Область применения"
        name="application_areas"
        :options="$store.state.records.properties.application_area"
        multiple
        searchable
        :disabled="!canEdit"
      />

      <VSelect
        label="Тип техники"
        name="tech_type"
        :options="$store.state.handbook.techType.list"
        searchable
        :disabled="!canEdit"
      />

      <VSelect
        label="Вид продукции"
        name="product_type"
        :options="$store.state.handbook.productType.list"
        searchable
        :disabled="!canEdit"
      />

      <VSelect
        label="Страна"
        name="country"
        :options="$store.state.handbook.country.list"
        :disabled="!canEdit"
      />
    </div>

    <h3>Прикрепить файлы</h3>

    <VInputFiles
      label="Файлы для запуска"
      class="product-general-fields__files"
      name="files_start"
      with-preview
      :is-edit="canEdit"
      @onRemoveFile="ADD_FILE_TO_DELETE"
    />

    <VInputFiles
      label="Файлы"
      class="product-general-fields__files"
      name="files_sales"
      with-preview
      :is-edit="canEdit"
      @onRemoveFile="ADD_FILE_TO_DELETE"
    />

    <VInputFiles
      v-if="$store.getters['permission/permissionReadProductFilesAdmin']"
      label="Файлы для администратора"
      class="product-general-fields__files"
      name="files_admin"
      with-preview
      :is-edit="$store.state.catalog.product.isEdit"
      @onRemoveFile="ADD_FILE_TO_DELETE"
    />

    <div class="flex gap-s">
      <VInput
        label="Применяемость"
        name="applicability"
        type="textarea"
        :disabled="!canEdit"
      />

      <VInput
        label="Примечание"
        name="note"
        type="textarea"
        :disabled="!canEdit"
      />
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Form/Vinput/VInput'
import VSelect from '@/components/Form/VSelect'
import VInputFiles from '@/components/Form/VInputFiles/VInputFiles'

import { mixinCatalogProductIsEdit } from '@/mixins/views/Catalog/Product/mixinCatalogProductIsEdit'
import { mapMutations } from 'vuex'

export default {
  name: 'ProductGeneralFields',
  components: { VInputFiles, VSelect, VInput },
  mixins: [mixinCatalogProductIsEdit],
  methods: {
    ...mapMutations('catalog/product', {
      ADD_FILE_TO_DELETE: 'ADD_FILE_TO_DELETE'
    })
  }
}
</script>
