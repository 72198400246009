<template>
  <div
    class="drag-item"
    draggable="true"
    @dragstart="onDragStart"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'DragItem',
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    onDragStart (event) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('from', this.index.toString())
    }
  }
}
</script>

<style>
.drag-item {
  width: 100%;
  height: 100%;
}
</style>
