import { mapState } from 'vuex'

export const mixinCatalogProductIsEdit = {
  computed: {
    ...mapState('catalog/product', {
      isEdit: 'isEdit'
    }),

    canEdit () {
      return this.isEdit && this.$store.getters['permission/isAdmin']
    }
  }
}
