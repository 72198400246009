// В компоненте должен быть определен this.springTypeId
export const mixinCatalogProductMainFields = {
  computed: {
    visibleWidth () {
      switch (this.springTypeId) {
        case 1:
        case 2:
        case 6:
        case 7: return false
        default: return true
      }
    },
    visibleTurns () {
      switch (this.springTypeId) {
        case 4:
        case 5:
        case 8: return false
        default: return true
      }
    },
    turnsLabel () {
      switch (this.springTypeId) {
        case 1:
        case 6: return 'n / n1'
        case 2:
        case 7: return 'n1'
        default: return 'n'
      }
    },
    diameterLabel () {
      switch (this.springTypeId) {
        case 5:
        case 9:
          return 'Диаметр внутр. (мм)'
        default:
          return 'Диаметр наружн. (мм)'
      }
    },
    lengthLabel () {
      switch (this.springTypeId) {
        case 2:
          return 'Длина по зацепам (мм)'
        default:
          return 'Длина (мм)'
      }
    }
  }
}
