<template>
  <div class="product-coating-price-fields">
    <div class="form-grid--col-2 mb-30">
      <h3>Без покрытия</h3>

      <h3>С покрытием</h3>
    </div>

    <div class="form-grid--col-2">
      <div
        v-for="(item, i) of modelValue"
        :key="i"
        class="d-contents"
      >
        <InputClear
          v-model="item.cost_not_coating"
          :label="item.name"
          :is-disabled="!canEdit"
          :has-button-remove="canEdit"
        />

        <InputClear
          v-model="item.cost_coating"
          :label="item.name"
          :is-disabled="!canEdit"
          :has-button-remove="canEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputClear from '@/components/Form/Vinput/InputClear'

import { mixinInputModel } from '@/mixins/form/mixinInputModel'
import { mixinCatalogProductIsEdit } from '@/mixins/views/Catalog/Product/mixinCatalogProductIsEdit'

export default {
  name: 'ProductCoatingPriceFields',
  components: { InputClear },
  mixins: [mixinInputModel, mixinCatalogProductIsEdit],
  props: {
    name: {
      type: String,
      required: false
    },
    value: {
      type: Array,
      required: false
    }
  }
}
</script>
