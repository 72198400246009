<template>
  <div
    class="drop-zone"
    @drop="onDrop"
    @dragover.prevent
    @dragenter.prevent
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'DropZone',
  props: {
    index: {
      type: Number,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onDrop (event) {
      const from = +event.dataTransfer.getData('from')

      if (from === this.index) return

      this.$emit('on-drop', { from, to: this.index })
    }
  }
}
</script>

<style>
.drop-zone {
  width: 100%;
  height: 100%;
}
</style>
