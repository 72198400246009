<template>
  <ModalForm
    title="Категории цен"
    class="modal-product-price-category"
    @request="saveProductPriceCategoryHandler"
    @reject="onReject"
  >
    <div class="modal-product-price-category__fields column gap-xxs mb-20">
      <InputClear
        v-for="(item, i) of priceCategoryList"
        :key="i"
        v-model="item.name"
        label="Наименование"
        is-emit-remove
        required
        @on-remove="removePriceCategory(i)"
      />
    </div>

    <VButton
      text="Добавить категорию"
      class="mb-30"
      icon="create"
      size="sm"
      color="primary-sm"
      @click.native="addPriceCategory"
    />
  </ModalForm>
</template>

<script>
import InputClear from '@/components/Form/Vinput/InputClear'
import ModalForm from '@/components/ui/Modal/ModalForm'
import VButton from '@/components/simple/button/VButton'
import { mockRequest } from '@/mocks/mockRequest'

export default {
  name: 'ModalProductPriceCategory',
  components: {
    InputClear,
    ModalForm,
    VButton
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    priceCategoryList: []
  }),
  mounted () {
    if (!this.value.length) {
      return this.addPriceCategory()
    }

    this.fillPriceCategoryList()
  },
  methods: {
    async saveProductPriceCategoryHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')
        await mockRequest()
        this.$emit('request', this.priceCategoryList)
      } catch (e) {
        await this.$store.dispatch('alert/setAlertError', e.message)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    fillPriceCategoryList () {
      for (const item of this.value) {
        this.priceCategoryList.push({ ...item })
      }
    },

    addPriceCategory () {
      this.priceCategoryList.push({
        name: '',
        not_coating: null,
        coating: null
      })
    },

    removePriceCategory (index) {
      this.priceCategoryList.splice(index, 1)
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
